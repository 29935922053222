import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  updateEmail
} from 'firebase/auth'
import { BiErrorCircle } from 'react-icons/bi'
import { NextRouter } from 'next/router'
import { writeToFirestore, addToContacts } from '@/util/finishSignup'

import { auth } from '@/util/firebase'
import redirectUser from '@/util/redirectUser'
import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

export const SubmitButton = ({ children, disabled }: { children: any, disabled: boolean }) => (
  <Button
    disabled={disabled}
    type="submit"
    className="
      font-soehne-kraftig text-sm
      text-white bg-black flex items-center justify-center 
      h-12 w-full  
    "
  >
    {children}
  </Button>
)

const SocialLoginButton = ({
  service,
  onClick,
}: {
  service: string
  onClick: () => void
}) => (
  <button
    className="flex font-soehne-kraftig text-sm items-center justify-center w-full bg-dark h-12"
    onClick={onClick}
  >
    <span className="ml-2 text-white"><span className="capitalize">{service}</span></span>
  </button>
)

export const GoogleSignin = ({ 
  router,
  setErrorMessage
}: { 
  router: NextRouter
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>
}) => {
  const handleGoogleSignin = () => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then((userCredential) => {
        writeToFirestore(userCredential)
        return userCredential
      })
      .then ((userCredential) => {
        if (userCredential.user.email) return addToContacts(userCredential.user.email)
      })
      .then(() => redirectUser(router))
      .catch((error) => {
        console.error('errorCode: ', error.code, '. errorMessage: ', error.message)
        setErrorMessage('Could not login with Google due to an internal error.')
      })
  }

  return (
    <SocialLoginButton
      service="google"
      onClick={handleGoogleSignin}
    />
  )
}

export const TwitterSignin = ({ 
  router,
  setErrorMessage,
  isLoggingIn
}: { 
  router: NextRouter
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>
  isLoggingIn: boolean
}) => {
  const handleTwitterSignin = () => {
    const provider = new TwitterAuthProvider()
    signInWithPopup(auth, provider)
      .then(async (userCredential) => {
        const handleTwitterEmail = async () => {
          if (userCredential.user.email) return
          if (!userCredential.user.providerData[0].email)
            throw 'error!  Twitter did not provide an email for us to use.'
          await updateEmail(userCredential.user, userCredential.user.providerData[0].email)
        }

        await handleTwitterEmail()
        await writeToFirestore(userCredential)
        if (userCredential.user.providerData[0].email) addToContacts(userCredential.user.providerData[0].email)
        redirectUser(router)
      })
      .catch((error) => {
        console.error('errorCode: ', error.code, '. errorMessage: ', error.message)
        if (error.code === 'auth/account-exists-with-different-credential') {
          setErrorMessage(
            isLoggingIn
            ? 'Please login using the login method you signed up with.'
            : 'You already have an account. Please login with the account you signed up with.'
          )
        }
        else (setErrorMessage('Could not login with Twitter due to an internal error.'))
      })
  }

  return (
    <SocialLoginButton
      service="twitter"
      onClick={handleTwitterSignin}
    />
  )
}

export const SocialsSignInArea = ({
  router,
  setErrorMessage,
  isLoggingIn
}: { 
  router: NextRouter
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>
  isLoggingIn: boolean
}) => 
  <div className="flex flex-col justify-center">
    <GoogleSignin router={router} setErrorMessage={setErrorMessage} />
    <br />
    <TwitterSignin router={router} setErrorMessage={setErrorMessage} isLoggingIn={isLoggingIn} />
  </div>

export const ErrorMessage = ({ errorMessage }: { errorMessage: string }) =>
  <div className="text-center mb-4 w-11/12 mx-auto">
    <BiErrorCircle className="inline-block text-error fill-current mb-px mr-1" />
    <span className="text-error text-sm">{errorMessage}</span>
  </div>


const Button = styled.button`
&:disabled {
  background: #b3b3b3;
  pointer-events: none;
}
`;