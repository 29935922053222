import { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { signInWithEmailAndPassword } from 'firebase/auth'
import type { NextRouter } from 'next/router'

import { auth } from '@/util/firebase'
import Input from '@/components/Input'
import { formSubmitTimeout } from '@/util/constants'
import {
  SubmitButton,
  ErrorMessage,
  SocialsSignInArea
} from '@/components/signin/signinMiscComponents'
import ResetPassword from '@/components/signin/ResetPassword'
import redirectUser from '@/util/redirectUser'
import { ImMail4 } from 'react-icons/im'

interface IinitialValuesLogin {
  email: string
  password: string
}
const initialValuesLogin: IinitialValuesLogin = {
  email: '',
  password: ''
}

export default function LoginSection({
  isMobile,
  router
}: {
  isMobile: boolean
  router: NextRouter
}) {
  const [errorMessage, setErrorMessage] = useState<string>()
  const [submitButtonText, setSubmitButtonText] = useState<string>('Sign in with email')
  const [shouldShowResetPass, setShouldShowResetPass] = useState<boolean>(false)

  const handleSubmitLogin = async (loginValues: IinitialValuesLogin) => {
    setSubmitButtonText('...')

    await signInWithEmailAndPassword(auth, loginValues.email, loginValues.password)
      .then(() => {
        setSubmitButtonText('SUCCESS!')
        setErrorMessage(undefined)
        setTimeout(() => {
          redirectUser(router)
        }, formSubmitTimeout)
      })
      .catch((error) => {
        const errorCode = error.code as string
        console.warn('Could not login. Code: ', errorCode)
        switch (errorCode) {
          case 'auth/wrong-password':
            setErrorMessage('Incorrect password!')
            setSubmitButtonText('LOGIN')
            break
          case 'auth/user-not-found':
            setErrorMessage('Email address not found!')
            setSubmitButtonText('LOGIN')
            break
          default:
            setErrorMessage('An internal error has occured!')
            setSubmitButtonText('LOGIN')
            break
        }
      })
  }

  const handleForgotPassClick = () => {
    setShouldShowResetPass(true)
  }

  return (
    <div className="dt:w-2/3 mb:w-footer-mob mx-auto relative">
      <ResetPassword
        isMobile={isMobile}
        setShouldShowResetPass={setShouldShowResetPass}
        shouldShowResetPass={shouldShowResetPass}
      />

      <div
        className={`
        transition-opacity
        ${shouldShowResetPass ? 'hidden' : 'block'}
      `}
      >
        <h1
          className="
          font-Josefin-med tracking-wide
          uppercase
          text-center mb-8
          text-4xl
          mb:mt-8
        "
        >
          Login
        </h1>

        {/* SOCIAL LOGIN */}
        <SocialsSignInArea router={router} setErrorMessage={setErrorMessage} isLoggingIn={true} />

        <p
          className="
          text-center text-sm mb-2 mt-5 font-mulish-med mb:leading-11 mb:text-13px
        "
        >
          Or, login using email
        </p>

        {/* EMAIL LOGIN */}
        <Formik initialValues={initialValuesLogin} onSubmit={handleSubmitLogin}>
          {(values: IinitialValuesLogin) => (
            <Form>
              <Field
                className="mb-4 dt:text-sm mb:text-11px font-mulish-med"
                name="email"
                type="email"
                label={isMobile ? 'EMAIL ADDRESS' : 'Email Address'}
                inputWidth="100%"
                autocomplete="email"
                component={Input}
              />

              <Field
                className="mb-1.5 font-mulish-med dt:text-sm mb:text-11px"
                name="password"
                type="password"
                label={isMobile ? 'PASSWORD' : 'Password'}
                inputWidth="100%"
                autocomplete="current-password"
                component={Input}
              />

              <p
                className={`
                text-center dt:text-xs mb:text-11px font-mulish-med
                ${errorMessage ? 'mb-2' : 'mb-5'}`}
              >
                <span onClick={handleForgotPassClick} className="cursor-pointer text-link">
                  Forgot your password?
                </span>
              </p>

              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

              <SubmitButton disabled={false}>
                <ImMail4 size={25} color="white" className="mr-2" />
                {submitButtonText}
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
