import { setDoc, doc } from "firebase/firestore"
import { UserCredential } from "@firebase/auth"
import { db } from '@/util/firebase'

export const addToContacts = async (email: string) => {
  fetch('/api/addToContacts', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      email: email
    })
  })
}

export const writeToFirestore = async (credential: UserCredential) => {
  const user = credential.user
  setDoc(doc(db, 'users', user.uid), {
    email: user.email
  }, { merge: true })
}