export default function Input({
  field,
  label,
  type,
  inputWidth,
  autocomplete,
  className,
  isRequired
}: {
  field: { [key: string]: any }
  label: string
  type: string
  inputWidth: string | number
  autocomplete?: string
  className: string,
  isRequired: boolean
}) {
  return (
    <div className={className}>
      <label htmlFor={label} className="block mb:mb-1">
        {label}
      </label>

      <input
        {...field}
        type={type}
        className="border border-black font-mulish-med h-12 rounded pl-1.5 w-full"
        style={{
          width: inputWidth
        }}
        autoComplete={autocomplete}
        required={isRequired}
      />
    </div>
  )
}
