import { useRouter, NextRouter } from 'next/router'
import Head from 'next/head'

import useIsMobile from '@/util/useIsMobile'
import { contentHeight } from '@/util/constants'
import LoginSection from '@/components/signin/LoginSection'

export default function Signin() {
  const isMobile = useIsMobile()
  const router = useRouter()

  if (isMobile === undefined) return null

  return (
    <>
      <Head>
        <title>Beyond The Pines Magazine — Login</title>
        <meta
          name="description"
          content="Login to Beyond The Pines Magazine. Beyond The Pines Magazine: breathtaking photography and visual provocation that stimulates thoughts, ideas, & conversations. "
        />
        <meta
          name="keywords"
          content="Beyond The Pines, Beyond The Pines Magazine, Beyond The Pines Mag, magazine, mag, BTP, BTP magazine, BTP mag, login, log in, Beyond The Pines login, Beyond the Pines log in, BTP login, BTP log in"
        />
      </Head>
      <div className="mb:pb-height-footer">
        <LoginLayout isMobile={isMobile} router={router} />
      </div>
    </>
  )
}

const LoginLayout = ({ isMobile, router }: { isMobile: boolean; router: NextRouter }) => {
  return (
    <div
      className="
        flex justify-center items-center h-full flex-col dt:pt-12
      "
      // If the header or footer height change, this has to change.
      // I couldn't figure out a way to do this purely dynamically.
      // style={{ height: isMobile ? '100%' : contentHeight }}
    >
      <div
        className="
          flex justify-center items-center w-full h-full
          mb:flex-col
        "
        
      >
        <div className="mb:w-full dt:w-1/2">
          <LoginSection isMobile={isMobile} router={router} />
        </div>
      </div>
    </div>
  )
}
