import { Dispatch, SetStateAction, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { sendPasswordResetEmail } from 'firebase/auth'

import { auth } from '@/util/firebase'
import Input from '@/components/Input'
import { SubmitButton } from '@/components/signin/signinMiscComponents'

interface IValuesResetPassword {
  email: string
}
const initialValuesResetPassword: IValuesResetPassword = {
  email: ''
}

export default function ResetPassword({
  isMobile,
  shouldShowResetPass,
  setShouldShowResetPass
}: {
  isMobile: boolean
  shouldShowResetPass: boolean
  setShouldShowResetPass: Dispatch<SetStateAction<boolean>>
}) {
  const [submitButtonText, setSubmitButtonText] = useState<string>('Reset')

  const handleSubmitResetPassword = (resetPasswordValues: IValuesResetPassword) => {
    setSubmitButtonText('...')

    sendPasswordResetEmail(auth, resetPasswordValues.email).then(() => {
      setSubmitButtonText('RESET EMAIL SENT')
      setTimeout(() => {
        setShouldShowResetPass(false)
      }, 5000)
    })
  }

  const handleNavigateBack = () => {
    setShouldShowResetPass(false)
  }

  return (
    <div
      className={`
      flex flex-col items-center justify-center
      transition-opacity
      ${shouldShowResetPass ? 'flex' : 'hidden'}
    `}
    >
      <h2
        className="
        font-Josefin-med tracking-wide
        uppercase
        text-center mb-8
        text-3xl
        mb:mt-8
      "
      >
        Reset Password
      </h2>

      <Formik initialValues={initialValuesResetPassword} onSubmit={handleSubmitResetPassword}>
        {() => (
          <Form className="w-full">
            <Field
              className="mb-2 dt:text-sm mb:text-11px font-mulish-med w-full"
              name="email"
              type="email"
              label={isMobile ? 'EMAIL ADDRESS' : 'Email Address'}
              inputWidth="100%"
              component={Input}
            />

            <p className="text-center dt:text-xs mb:text-11px font-mulish-med mb-5">
              <span onClick={handleNavigateBack} className="cursor-pointer text-link">
                Go back to login page
              </span>
            </p>

            <SubmitButton disabled={false}>{submitButtonText}</SubmitButton>
          </Form>
        )}
      </Formik>
    </div>
  )
}
